import * as React from 'react';
import './App.css';


export default () => {
  // const [redirect, setRedirect] = React.useState<undefined | JSX.Element>(undefined);
  const [msg, setMsg] = React.useState<string | undefined>(undefined);

  const navigate = (platform: string, app: string, url : string) => {
    const fallbackUrl = `https://ahsonline.sharepoint.com?userAgent=${window.navigator.userAgent}`;
    if(window.location.href.indexOf('test=true') > -1) {
      setMsg(`UserAgent = ${navigator.userAgent}; Platform = ${platform}; app = ${app}; url = ${url}`);
    } else {
      (url) ? window.location.replace(url) : window.location.replace(fallbackUrl);
    }

  }

  React.useEffect( () => {
    let application = '';
    if(document.location.href.indexOf('?') > -1 && document.location.href.indexOf('=') > -1) {
      //Query String Present
      let queryObj: {[key: string]: string} = {};
      const queryString = document.location.href.split('?')[1];
      const queryArray = queryString.split('&');
      queryArray.forEach( keyValueString => {
        const key = keyValueString.split('=')[0];
        const value = keyValueString.split('=')[1];
        queryObj[key] = value;
      });

      application = queryObj.app;
    }

    const manager_mobile = 'https://ahsonline.sharepoint.com/sites/Covid19/SitePages/Redeployment-Manager-Portal.aspx';
    const manager_desktop = 'https://apps.powerapps.com/play/f9fce5e5-9191-4d6e-8daa-1ed74e228012?tenantId=6ac36678-7785-476f-be03-b68b403734c2';
    const employee_mobile = 'https://ahsonline.sharepoint.com/sites/Covid19/SitePages/Redeploy.aspx';
    const employee_desktop = 'https://apps.powerapps.com/play/d68484a6-a1a0-486c-8ec7-f16dbaa103d3?tenantId=6ac36678-7785-476f-be03-b68b403734c2';
    // const unkown_application = 'https://ahsonline.sharepoint.com/sites/Covid19/SitePages/Redeployment.aspx';
    const unkown_application = 'https://ahsonline.sharepoint.com/sites/Covid19/SitePages/Redeployment.aspx';

    const uA = window.navigator.userAgent;
    // const url = 'https://apps.powerapps.com/play/eaa4ee86-e530-47bb-85c7-717bf491e80b?tenantId=6ac36678-7785-476f-be03-b68b403734c2';
    if(application === 'manager') {
      if(uA.indexOf("Mac") !== -1 && uA.toLowerCase().indexOf("like mac") === -1) navigate('Mac', application, manager_desktop)
      else if(uA.indexOf("Windows") !== -1) navigate('Windows', application, manager_desktop)
      else if(uA.indexOf("iPhone") !== -1) navigate('iPhone', application, manager_mobile)
      else if(uA.indexOf("iPad") !== -1) navigate('iPad', application, manager_mobile)
      else if(uA.indexOf("Android") !== -1) navigate('Android', application, manager_mobile)
      else navigate('UnkownPlatform', application, manager_mobile);
    }
    
    if(application === 'employee') {
      if(uA.indexOf("Mac") !== -1 && uA.toLowerCase().indexOf("like mac") === -1) navigate('Mac', application, employee_desktop) 
      else if(uA.indexOf("Windows") !== -1) navigate('Windows', application, employee_desktop)
      else if(uA.indexOf("iPhone") !== -1) navigate('iPhone', application, employee_mobile)
      else if(uA.indexOf("iPad") !== -1) navigate('iPad', application, employee_mobile)
      else if(uA.indexOf("Android") !== -1) navigate('Android', application, employee_mobile)
      else navigate('UnkownPlatform', application, employee_mobile);
    }

    if(application !== 'manager' && application !== 'employee') {
      if(uA.indexOf("Mac") !== -1 && uA.toLowerCase().indexOf("like mac") === -1) navigate('Mac', application, employee_desktop) 
      else if(uA.indexOf("Windows") !== -1) navigate('Windows', application, employee_desktop)
      else if(uA.indexOf("iPhone") !== -1) navigate('iPhone', application, employee_mobile)
      else if(uA.indexOf("iPad") !== -1) navigate('iPad', application, employee_mobile)
      else if(uA.indexOf("Android") !== -1) navigate('Android', application, employee_mobile)
      else navigate('UnkownPlatform', application, employee_mobile);
    }
  }, []);
  return <>{msg}</>
}
